








































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { useLocation, useReceiveItem } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ReceivingItemAssetRequestDto } from "@/models/interface/receive-item";
import {
  FormValue,
  ReceiveItemRow,
  State,
} from "@/store/goodsReceiptPrice.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import ModalAssetLine from "./ModalAssetLine.vue";

@Component({
  components: {
    ModalAssetLine,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      store: (st: any) => st.goodsReceiptPriceStore,
    }),
    ...mapGetters({
      form: "goodsReceiptPriceStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      toggleModalAsset: "goodsReceiptPriceStore/toggleModalAsset",
      deleteRow: "goodsReceiptPriceStore/removeLine",
      calculateLines: "goodsReceiptPriceStore/calculateLines",
    }),
  },
})
export default class FormTable extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  store!: State;
  form!: FormValue;
  toggleModalAsset!: (payload: Array<ReceivingItemAssetRequestDto>) => void;
  deleteRow!: (rowKeys: Array<number>) => void;
  calculateLines!: () => void;

  locationOptions: Option[] = [];
  selectedRowKeys: Array<number> = [];
  columns = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_qty"),
      dataIndex: "qty",
      width: "100px",
      scopedSlots: { customRender: "receiptQty" }, // input manual
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_price"),
      dataIndex: "price",
      scopedSlots: { customRender: "unitPrice" }, // input manual
    },
    {
      title: this.$t("lbl_tax_base_dpp"),
      dataIndex: "taxBase",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_discount_amount"),
      dataIndex: "discountValue",
      scopedSlots: { customRender: "discount" }, // input manual
    },
    {
      title: this.$t("lbl_tax_value"),
      dataIndex: "tax",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_total_idr"),
      dataIndex: "totalPrice",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_receipt_location"),
      dataIndex: "locationReceived",
      width: "300px",
      scopedSlots: { customRender: "receiptLocation" }, // input manual
    },
    {
      title: this.$t("lbl_track_as_asset"),
      dataIndex: "trackAsAsset", // input manual
      width: "150px",
      scopedSlots: { customRender: "trackAsAsset" },
    },
    {
      // ini buat nampilin additional data dalam bentuk modals
      // serial number, type, specification, capacity
      title: this.$t("lbl_notes"),
      scopedSlots: { customRender: "notes" },
    },
  ];

  mounted() {
    this.getLocationList();
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  viewItemAssets(row: ReceiveItemRow): void {
    const { initItemAssetDto } = useReceiveItem();
    const asset = initItemAssetDto();
    if (row.itemAssets.length === 0) {
      row.itemAssets.push(asset);
    }
    this.toggleModalAsset(row.itemAssets);
  }

  handleCloseModal(): void {
    const empty: Array<ReceivingItemAssetRequestDto> = [];
    this.toggleModalAsset(empty);
  }

  handleDeleteRow(): void {
    this.showConfirmationDeleteItems(() => {
      this.deleteRow(this.selectedRowKeys);
      this.selectedRowKeys = [];
      this.calculateLines();
    });
  }

  async fetchLocationList(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ) {
    try {
      const { findAll, toOptions } = useLocation();
      const queries: string[] = [
        new SearchBuilder()
          .push([
            "warehouse.branchWarehouse.secureId",
            this.form.branch?.key ?? "",
          ])
          .build(),
      ];
      if (params.search) {
        queries.unshift(params.search);
      }
      params.search = queries.join(SearchBuilder.AND);
      const response = await findAll(params);
      return toOptions(response.data, "id");
    } catch (error) {
      return [];
    }
  }

  async getLocationList() {
    try {
      this.locationOptions = await this.fetchLocationList();
    } catch (error) {
      this.locationOptions = [];
    }
  }

  onSearchLocation(record: ReceiveItemRow, search?: string) {
    record.isSearchLocation = true;
    const { searchBy } = useLocation();
    const query = searchBy({ name: search });
    const params = new RequestQueryParams();
    params.search = query;
    debounce(async () => {
      try {
        record.loadingLocation = true;
        record.locationOptions = await this.fetchLocationList(params);
      } catch {
        record.locationOptions = [];
      } finally {
        record.loadingLocation = false;
      }
    }, 500);
  }
}
